<template>
  <div>
    <div class="list-page">
      <!--  -->
      <div class="left">
        <div>
          <a-list
            :loading="loading"
            size="large"
            :locale="locale"
            :data-source="listData1"
            class="list-art"
          >
            <a-list-item
              v-for="item in listData1"
              slot="renderItem"
              :key="item.url"
              slot-scope="item, index"
              @click="handleVideo(item)"
            >
              <template #extra>
                <div class="list-item">
                  <img
                    v-if="item.surfacePlot"
                    class="cover"
                    width="272"
                    alt="logo"
                    :src="item.surfacePlot"
                  />
                  <div class="r" slot="title">
                    <h3 class="title" v-if="item.title">
                      {{ item.title }}
                    </h3>
                    <p class="time" v-if="item.publishTime">
                      {{ item.publishTime }}
                    </p>
                  </div>
                </div>
              </template>
            </a-list-item>
          </a-list>
        </div>
      </div>
      <div class="right">
        <a-list
          size="large"
          :data-source="listData2"
          :locale="locale"
          class="list-art"
          :loading="loading"
        >
          <a-list-item
            slot="renderItem"
            v-for="item in listData2"
            :key="item.url"
            slot-scope="item, index"
            @click="handleVideo(item)"
          >
            <template #extra>
              <div class="list-item">
                <img
                  v-if="item.surfacePlot"
                  class="cover"
                  width="272"
                  alt="logo"
                  :src="item.surfacePlot"
                />
                <div class="r">
                  <h3 class="title">
                    {{ item.title }}
                  </h3>
                  <p class="time" v-if="item.publishTime">
                    {{ item.publishTime }}
                  </p>
                </div>
              </div>
            </template>
          </a-list-item>
        </a-list>
      </div>
    </div>
    <div class="bottom-pagination" v-if="!$route.query.searchData">
      <a-pagination
        v-model="params.pageNum"
        :total="total"
        show-quick-jumper
        :defaultPageSize="params.pageSize"
        @change="onChangePagination"
      />
    </div>
  </div>
</template>

<script>
import { getVideoList } from '@/api'
export default {
  data() {
    return {
      locale: {
        emptyText: ' '
      },
      loading: false,
      list: [],
      listData: [],
      listData1: [],
      listData2: [],
      categoryId: '',
      params: {
        pageNum: 1, //页码
        pageSize: 10 //每页条数
      },
      total: 0
    }
  },
  watch: {},
  created() {
    this.getVideoList()
  },
  methods: {
    async getVideoList() {
      const { data, code, total } = await getVideoList(this.params)

      try {
        if (code === 200) {
          this.total = total
          this.list = [...data]
          this.listData = [
            ...this.$_.chunk([...data], Math.ceil(data.length / 2))
          ]
          this.listData1 = [...this.listData[0]]
          this.listData2 = [...this.listData[1]]
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
    onChangePagination(pageNum, pageSize) {
      this.params.pageNum = pageNum
      this.getVideoList()
    },
    handleVideo(it) {
      this.$router.push({
        path: '/videoplay',
        query: {
          url: it.url,
          title: it.title
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.bottom-pagination {
  text-align: center;
  margin-top: 16px;
  padding-right: 72px;
}
.list-page {
  padding: 0 160px;
  display: flex;
  justify-content: space-between;
  .left {
    width: 800px;
    .moulde {
      width: 800px;
      margin-bottom: 52px;
      .img-content {
        position: relative;
        cursor: pointer;
        > img {
          width: 800px;
          height: 446px;
          object-fit: cover;
          background: #d8d8d8;
        }
        .img-name {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 800px;
          height: 58px;
          background: rgba(0, 0, 0, 0.1);
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 58px;
          padding-left: 15px;
        }
      }
      .title {
        width: 800px;
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #212223;
        line-height: 33px;
        margin: 20px 0;
      }
    }
  }
  .right {
    width: 740px;
  }
  .list-art {
    width: 800px;
    .list-item {
      display: flex;
      height: 150px;
      cursor: pointer;
    }
    .cover {
      width: 300px;
      height: 150px;
      object-fit: cover;
      background: #d8d8d8;
    }
    .r {
      margin-left: 12px;
      position: relative;
      h3 {
        margin: 0;
        .cirle {
          display: inline-block;
          width: 14px;
          height: 14px;
          background: #212223;
          border-radius: 50%;
          line-height: 33px;
          margin-right: 12px;
        }
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #212223;
      }
      .time {
        position: absolute;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        white-space: nowrap;
      }
    }
  }
  .ant-list-item {
    &:nth-child(1) {
      padding-top: 0;
    }
  }
}
</style>
