<template>
  <div class="details">
    <a-breadcrumb separator="">
      <a-breadcrumb-item>所在位置</a-breadcrumb-item>
      <a-breadcrumb-separator>:</a-breadcrumb-separator>
      <a-breadcrumb-item class="backHome">
        <span @click="backHome" style="cursor: pointer;">国民健康养老网</span>
      </a-breadcrumb-item>
      <a-breadcrumb-separator />
      <a-breadcrumb-item
        ><a>{{ $route.query.title }}</a></a-breadcrumb-item
      >
    </a-breadcrumb>
    <div class="top">
      <div class="title">
        {{ $route.query.title }}
      </div>
    </div>
    <a-divider />
    <!--  -->
    <div class="moulde">
      <div class="img-content">
        <video :src="$route.query.url" controls class="play"></video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      articleId: '',
      url: ''
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(val) {
        this.url = val.url
      }
    }
  },
  methods: {
    backHome() {
      this.$store.commit('layout/setLayoutTitle', '')
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.details {
  padding: 0 160px;
  .ant-breadcrumb {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  }
  .top {
    .title {
      text-align: center;
      height: 42px;
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #212223;
      line-height: 42px;
      margin: 30px 0;
    }
    .time {
      text-align: center;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: 28px;
    }
  }
  .moulde {
    margin-left: 50%;
    transform: translateX(-50%);
    width: 1200px;
    margin-bottom: 52px;
    .img-content {
      position: relative;
      cursor: pointer;
      .play {
        width: 100%;
        height: 100%;
      }
      .img-name {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 800px;
        height: 58px;
        background: rgba(0, 0, 0, 0.1);
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 58px;
        padding-left: 15px;
      }
    }
  }
  .art-content {
    font-size: 20px;
    font-weight: 400;
    color: #212223;
  }
  .author {
    text-align: right;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #212223;
    line-height: 28px;
    span {
      display: inline-block;
      &:nth-child(2) {
        margin: 0 20px;
      }
    }
  }
}
</style>
